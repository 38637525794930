import React from 'react'

// 无法登录服务器
const Logout = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="首页-已登录" transform="translate(-1512.000000, -8.000000)" fillRule="nonzero">
                    <g id="Group-6">
                        <g id="个人信息" transform="translate(1391.000000, 5.000000)">
                            <g id="icon-退出" transform="translate(120.000000, 2.000000)">
                                <rect id="Rectangle-path" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
                                <path d="M14.859375,7.684375 C15.046875,7.8796875 15.046875,8.1828125 14.859375,8.378125 L11.95,11.4046875 C11.7703125,11.584375 11.475,11.5921875 11.2875,11.4046875 C11.1,11.209375 11.1,10.8984375 11.2875,10.703125 L13.39375,8.51875 L7.79375,8.51875 C7.5203125,8.51875 7.303125,8.3 7.303125,8.028125 C7.303125,7.75625 7.521875,7.5375 7.79375,7.5375 L13.346875,7.5375 L11.2875,5.3671875 C11.1,5.171875 11.1,4.86875 11.2875,4.6734375 C11.4515625,4.49375 11.740625,4.478125 11.91875,4.6421875 C11.9265625,4.65 11.9421875,4.6578125 11.95,4.6734375 L14.859375,7.684375 Z M9.696875,12.3015625 C9.9625,12.309375 10.1796875,12.528125 10.171875,12.7921875 L10.171875,13.3453125 C10.1875,14.2421875 9.4703125,14.9828125 8.5734375,14.9984375 L2.5984375,14.9984375 C1.7015625,14.984375 0.984375,14.24375 1,13.346875 L1,2.653125 C0.984375,1.75625 1.7015625,1.015625 2.5984375,1 L8.58125,1 C9.478125,1.015625 10.1953125,1.75625 10.1796875,2.653125 L10.1796875,3.20625 C10.1875,3.471875 9.96875,3.696875 9.7046875,3.696875 C9.4390625,3.6890625 9.221875,3.4703125 9.2296875,3.20625 L9.2296875,2.653125 C9.2375,2.29375 8.9484375,1.9984375 8.5828125,1.990625 L2.5984375,1.990625 C2.2390625,1.9984375 1.9515625,2.2953125 1.9515625,2.653125 L1.9515625,13.3453125 C1.94375,13.7046875 2.2328125,14 2.5984375,14.0078125 L8.58125,14.0078125 C8.940625,14 9.228125,13.703125 9.228125,13.3453125 L9.228125,12.7921875 C9.228125,12.659375 9.275,12.534375 9.36875,12.4484375 C9.446875,12.35625 9.571875,12.3015625 9.696875,12.3015625 Z" id="Shape" fill="#666666"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default Logout