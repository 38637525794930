import React from 'react'

const HotQuiz = (props) => {
    const { width, height } = props
    return (
      <section>
         <style jsx>{`
             
         `}
         </style>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-1100.000000, -177.000000)" fillRule="nonzero">
                    <g id="常见问题" transform="translate(1086.000000, 164.000000)">
                        <g id="icon-热门问题" transform="translate(12.000000, 12.000000)">
                            <rect id="Rectangle-path" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
                            <path d="M3.2328125,5.4484375 C4.0640625,4.5078125 4.7296875,3.4609375 4.6484375,2.309375 C4.640625,2.1921875 4.6421875,2.075 4.634375,1.959375 C4.6296875,1.903125 4.671875,1.8546875 4.7296875,1.85 C4.7640625,1.8484375 4.796875,1.8453125 4.8296875,1.8453125 C7.3328125,1.8453125 9.3625,3.7828125 9.3625,6.171875 C9.3625,6.3515625 9.35,6.5265625 9.3265625,6.7 C9.3140625,6.7953125 9.4265625,6.8515625 9.4953125,6.784375 C10.2171875,6.0875 10.69375,5.1609375 10.7890625,4.125 C10.7953125,4.053125 10.875,4.0109375 10.940625,4.0484375 C12.603125,5.025 13.7140625,6.771875 13.7140625,8.771875 C13.7140625,11.0578125 12.2625,13.0203125 10.190625,13.865625 C9.815625,14.01875 9.3984375,14.040625 9.0078125,13.928125 C8.1125,13.671875 7.265625,13.1984375 6.55625,12.509375 C6.290625,12.25 6.059375,11.9703125 5.8546875,11.678125 C5.8078125,11.609375 5.703125,11.625 5.678125,11.7015625 C5.4671875,12.3125 5.3671875,12.95 5.3765625,13.5890625 C5.378125,13.6640625 5.3,13.7140625 5.23125,13.68125 C3.3796875,12.7546875 2.1109375,10.9078125 2.1109375,8.771875 C2.1109375,7.58125 2.4875,6.4359375 3.1578125,5.5328125" id="Shape" fill="#C1222B"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default HotQuiz