import React from 'react'

// 举报外挂
const AccountWaigua = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -883.000000)">
                    <g id="Group-Copy-10" transform="translate(642.000000, 883.000000)">
                        <g id="icon11">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <circle id="Oval" stroke="#F57A7A" strokeWidth="1.5" cx="25.5" cy="25.5" r="10.5"></circle>
                            <path d="M25.154,21 L26.904,21 L26.582,28.168 L25.476,28.168 L25.154,21 Z M26.022,28.994 C26.316,28.994 26.568,29.078 26.764,29.274 C26.96,29.456 27.058,29.694 27.058,29.988 C27.058,30.282 26.96,30.52 26.764,30.716 C26.554,30.898 26.302,30.996 26.022,30.996 C25.742,30.996 25.504,30.898 25.308,30.702 C25.098,30.506 25,30.268 25,29.988 C25,29.694 25.098,29.456 25.308,29.274 C25.504,29.078 25.742,28.994 26.022,28.994 Z" id="！" fill="#F57A7A"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountWaigua