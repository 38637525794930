import React from 'react';
import Complaint from './complaint';
import Faq from './faq';
import Hotline from './hotline';
import Vip from './vip';
import Myquiz from './myquiz';
import HotQuiz from './hotQuiz';
import Announcement from './announcement';
import OnlineClose from './onlineClose';
import OnlineService from './onlineService';
import VipZhongShen from './vipZhongShen';
import VipAnQuan from './vipAnQuan';
import VipGuanHuai from './vipGuanHuai';
import VipLiBao from './vipLiBao';
import VipYiDuiYi from './vipYiDuiYi';
import VipZhuanShu from './vipZhuanShu';
import VipZhuanXian from './vipZhuanXian';
import VipQuDao from './vipQuDao';
import QuestionCircle from './questionCircle';
import AccountFangchenmi from './accountFangchenmi';
import AccountFuwu from './accountFuwu';
import AccountGaowei from './accountGaowei';
import AccountJiefeng from './accountJiefeng';
import AccountQian from './accountQian';
import AccountShouji from './accountShouji';
import AccountTousu from './accountTousu';
import AccountVip from './accountVip';
import AccountWaigua from './accountWaigua';
import AccountWanhui from './accountWanhui';
import AccountZhapian from './accountZhapian';
import FormDelete from './formDelete';
import Logout from './logout';
import AddMore from './addmore';

export const selectIcons = new Map([
    ['Complaint', props => ( <Complaint {...props} />)],
    ['Faq', props => ( <Faq {...props} />)],
    ['Hotline', props => ( <Hotline {...props} />)],
    ['Vip', props => ( <Vip {...props} />)],
    ['Myquiz', props => ( <Myquiz {...props} />)],
    ['HotQuiz', props => ( <HotQuiz {...props} />)],
    ['Announcement', props => ( <Announcement {...props} />)],
    ['IconOnlineService', props => ( <OnlineService {...props} />)],
    ['IconOnlineClose', props => ( <OnlineClose {...props} />)],
    ['VipZhongShen', props => ( <VipZhongShen {...props} />)],
    ['VipAnQuan', props => ( <VipAnQuan {...props} />)],
    ['VipGuanHuai', props => ( <VipGuanHuai {...props} />)],
    ['VipLiBao', props => ( <VipLiBao {...props} />)],
    ['VipYiDuiYi', props => ( <VipYiDuiYi {...props} />)],
    ['VipZhuanShu', props => ( <VipZhuanShu {...props} />)],
    ['VipQuDao', props => ( <VipQuDao {...props} />)],
    ['VipZhuanXian', props => ( <VipZhuanXian {...props} />)],
    ['QuestionCircle', props => ( <QuestionCircle {...props} />)],
    ['AccountFangchenmi', props => ( <AccountFangchenmi {...props} />)],
    ['AccountFuwu', props => ( <AccountFuwu {...props} />)],
    ['AccountGaowei', props => ( <AccountGaowei {...props} />)],
    ['AccountJiefeng', props => ( <AccountJiefeng {...props} />)],
    ['AccountQian', props => ( <AccountQian {...props} />)],
    ['AccountShouji', props => ( <AccountShouji {...props} />)],
    ['AccountTousu', props => ( <AccountTousu {...props} />)],
    ['AccountVip', props => ( <AccountVip {...props} />)],
    ['AccountWaigua', props => ( <AccountWaigua {...props} />)],
    ['AccountWanhui', props => ( <AccountWanhui {...props} />)],
    ['AccountZhapian', props => ( <AccountZhapian {...props} />)],
    ['FormDelete', props => ( <FormDelete {...props} />)],
    ['Logout', props => ( <Logout {...props} />)],
    ['AddMore', props => ( <AddMore {...props} />)],
]) 