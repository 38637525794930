import React from 'react'

const VipZhongShen = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 60 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor="#B38D57" offset="0%"></stop>
                    <stop stopColor="#B18952" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-682.000000, -445.000000)">
                    <g id="VIP玩家终身福利" transform="translate(602.000000, 383.000000)">
                        <g id="icon-VIP终身" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14-Copy" x="0" y="0" width="80" height="80"></rect>
                            <g id="VIP" transform="translate(10.000000, 13.000000)" fillRule="nonzero">
                                <path d="M52.601162,50.7560628 L26.2100845,50.7560628 L19.1109649,26.6709631 C18.5594796,24.8175744 20.1337194,23 22.2794985,23 L56.5417751,23 C58.6875542,23 60.251767,24.8175744 59.7103087,26.6709631 L52.601162,50.7560628 Z" id="Shape" fill="#FFF5CF"></path>
                                <path d="M43.8573466,52.724679 L16.1426534,52.724679 C12.5820257,52.724679 9.45791726,50.3195435 8.55064194,46.8787447 L0.0941512126,14.8330956 C-0.0427960057,14.3195435 0.0941512126,13.7717546 0.462196862,13.3865906 C0.830242511,13.0014265 1.36091298,12.8302425 1.88302425,12.9329529 L17.1012839,15.9800285 L28.7760342,0.582025678 C29.3580599,-0.188302425 30.6419401,-0.188302425 31.2239658,0.582025678 L42.8987161,15.9800285 L58.1169757,12.9329529 C58.639087,12.8302425 59.1697575,13.0014265 59.5378031,13.3865906 C59.9058488,13.7717546 60.042796,14.3195435 59.9058488,14.8330956 L51.4493581,46.8787447 C50.5420827,50.3195435 47.4179743,52.724679 43.8573466,52.724679 Z M3.68901569,16.425107 L11.5206847,46.0912981 C12.0770328,48.1883024 13.9771755,49.6519258 16.1426534,49.6519258 L43.8573466,49.6519258 C46.0228245,49.6519258 47.9229672,48.1883024 48.4793153,46.0912981 L56.3109843,16.425107 L42.5563481,19.1726106 C41.9743224,19.2838802 41.3837375,19.0613409 41.0328103,18.5905849 L30,4.03994294 L18.9671897,18.5905849 C18.6077033,19.0613409 18.0171184,19.2838802 17.4436519,19.1726106 L3.68901569,16.425107 Z" id="Shape" fill="#B18952"></path>
                                <path d="M30,40.296719 C28.853067,40.296719 27.8088445,39.6718973 27.2610556,38.6704708 L20.9700428,27.0813124 C20.5677603,26.3366619 20.8416548,25.403709 21.5863053,25.0014265 C22.3309558,24.5991441 23.2639087,24.8730385 23.6661912,25.617689 L29.957204,37.1982882 L36.3252496,25.6091298 C36.7275321,24.8644793 37.660485,24.5905849 38.4051355,24.9928673 C39.149786,25.3951498 39.4236805,26.3281027 39.021398,27.0727532 L32.7303852,38.6619116 C32.1911555,39.6718973 31.1383738,40.296719 30,40.296719 Z" id="Shape" fill="url(#linearGradient-1)"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipZhongShen