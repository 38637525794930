import React from 'react'

// 绑定手机和更换手机
const AccountShouji = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-1100.000000, -338.000000)">
                    <g id="Group-Copy" transform="translate(1100.000000, 338.000000)">
                        <g id="icon2">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <g id="Group-3" transform="translate(13.000000, 14.000000)" fillRule="nonzero">
                                <rect id="Rectangle-path" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
                                <path d="M16.7999531,0.000140625 L7.20004688,0.000140625 C5.34733594,0.000140625 3.84009375,1.50738281 3.84009375,3.36009375 L3.84009375,20.6398828 C3.84009375,22.4925937 5.34733594,23.9998125 7.20004688,23.9998125 L16.7999531,23.9998125 C18.6526641,23.9998125 20.1598828,22.4925703 20.1598828,20.6398828 L20.1598828,3.36009375 C20.1598828,1.50738281 18.6526641,0.000140625 16.7999531,0.000140625 Z M18.2399063,20.6399062 C18.2399063,21.4339453 17.5939688,22.0798594 16.7999531,22.0798594 L7.20004688,22.0798594 C6.40600781,22.0798594 5.76009375,21.4339219 5.76009375,20.6399062 L5.76009375,3.36009375 C5.76009375,2.56605469 6.40603125,1.92011719 7.20004688,1.92011719 L16.7999531,1.92011719 C17.5939922,1.92011719 18.2399063,2.56605469 18.2399063,3.36009375 L18.2399063,20.6399062 Z" id="Shape" fill="#F57A7A"></path>
                                <polygon id="Shape" fill="#F57A7A" points="8.88004687 3.12011719 15.1199531 3.12011719 15.1199531 5.04009375 8.88004687 5.04009375"></polygon>
                                <path d="M10.8000234,19.6799062 C10.8000234,20.1086166 11.0287377,20.5047613 11.4000117,20.7191165 C11.7712858,20.9334717 12.2287142,20.9334717 12.5999883,20.7191165 C12.9712623,20.5047613 13.1999766,20.1086166 13.1999766,19.6799062 C13.1999766,19.0171775 12.6627288,18.4799297 12,18.4799297 C11.3372712,18.4799297 10.8000234,19.0171775 10.8000234,19.6799062 Z" id="Shape" fill="#F57A7A"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountShouji