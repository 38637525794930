import React from 'react'
                         
const Compalint = (props) => {
    const {width, height} = props;
   return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 47 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-386.000000, -587.000000)">
                    <g id="guide2" transform="translate(360.000000, 164.000000)">
                        <g id="投诉通道" transform="translate(0.000000, 400.000000)">
                            <g id="icon-投诉" transform="translate(20.000000, 20.000000)">
                                <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="0" y="0" width="60" height="60"></rect>
                                <path d="M52.8362071,18.1102413 C52.8034093,15.629843 52.7641959,12.9223787 52.7598788,9.95337164 C52.7575404,9.05422125 52.1753351,8.26257803 51.3287091,8.0048727 C44.4760752,5.93897338 38.3608213,4.72827422 30.2231987,3.82708523 C30.1188094,3.81611268 30.0138206,3.81407408 29.906973,3.81611268 C25.7747547,4.03214619 21.9092355,4.54707709 18.4161238,5.35203135 C14.9167764,6.15938396 11.5768009,7.05385752 8.49620953,8.00727111 C7.656299,8.2668951 7.08044941,9.05655967 7.08044941,9.95343164 C7.08044941,12.6302568 7.05178887,15.0909884 7.02774516,17.3729212 C6.81411,36.9553444 6.73346467,44.2861541 25.8599569,54.904175 C27.44828,55.7810205 28.7288918,56.1761527 30.0138206,56.1761527 C31.2877769,56.1761527 32.5620929,55.7942716 34.1417819,54.936733 C53.1962629,44.5674233 53.113519,38.4324428 52.8362071,18.1102413 Z" id="Shape" fill="#FFB5B5" fillRule="nonzero"></path>
                                <path d="M30,56.1761374 C28.7197049,56.1733131 27.4425853,55.7778767 25.8599569,54.904175 C6.73346467,44.2861541 6.81411,36.9553444 7.02774516,17.3729212 C7.05178887,15.0909884 7.08044941,12.6302568 7.08044941,9.95343164 C7.08044941,9.05655967 7.656299,8.2668951 8.49620953,8.00727111 C11.5768009,7.05385752 14.9167764,6.15938396 18.4161238,5.35203135 C21.9092355,4.54707709 25.7747547,4.03214619 29.906973,3.81611268 C29.9381297,3.81551822 29.9691283,3.81527046 30,3.81548979 L30,56.1761374 Z" id="Combined-Shape" fill="#F57A7A" fillRule="nonzero"></path>
                                <rect id="Rectangle-29" fill="#FFFFFF" x="27.5" y="12.5" width="5" height="22.5" rx="2.5"></rect>
                                <circle id="Oval-2" fill="#FFFFFF" cx="30" cy="42.5" r="2.5"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
   )
}
                          
export default Compalint