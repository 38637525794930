import React from 'react'

// 大客户经理申请
const AccountVip = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -447.000000)">
                    <g id="Group-Copy-2" transform="translate(642.000000, 447.000000)">
                        <g id="icon3">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="13" y="14" width="24" height="24"></rect>
                            <path d="M17.6804408,29.295159 L15.5970799,20.0000342 L13,20.0000342 L16.1279339,33 L18.7499174,33 L21.8957576,20.0000342 L19.8505234,20.0000342 L17.6804408,29.295159 Z M23.6155096,33 L26.0492562,33 L26.0492562,20 L23.6155096,20 L23.6155096,33 Z M36.4279339,21.6760288 C36.0463912,21.0477189 35.6127824,20.6114229 35.1270248,20.3667649 C34.6412947,20.1225513 33.9408815,20 33.0253994,20 L28.9209366,20 L28.9209366,32.9999658 L31.4001928,32.9999658 L31.4001928,28.1712088 L33.1168044,28.1712088 C33.8596143,28.1712088 34.404876,28.1142576 34.7533058,27.9998764 C35.1017631,27.8854953 35.4615151,27.6538615 35.8328926,27.304941 C36.2042975,26.9555418 36.491708,26.4763444 36.6950964,25.8668358 C36.8985124,25.2573273 37,24.6318888 37,23.9905204 C37.0000275,23.0760183 36.809449,22.3048516 36.4279339,21.6760288 Z M34.1773554,25.2858029 C33.9331405,25.6352021 33.5084573,25.809406 32.9033058,25.809406 L31.316584,25.809406 L31.316584,22.3618028 L32.8955096,22.3618028 C33.4500826,22.3618028 33.8630854,22.5176839 34.1353443,22.8284546 C34.4075757,23.1397039 34.5436639,23.5586 34.5436639,24.0855873 C34.5436914,24.5368219 34.4215702,24.9364038 34.1773554,25.2858029 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountVip