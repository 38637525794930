import { combineReducers, createStore } from 'redux';
import showReducer from './showReducer';
import formReducer from './formReducer';
import questionReducer from './questionReducer';

const store = createStore(combineReducers({
    showReducer,
    formReducer,
    questionReducer
}))

export default store