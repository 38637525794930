import React from 'react'
import { selectIcons } from './iconsList';

const BaseIcon = (props) => {
    const { iconName } = props;
    const SvgFunc = selectIcons.get(iconName);
    return (
      <section className="icon-line-height">
         <SvgFunc {...props} />
      </section>
    )
}
                          
export default BaseIcon