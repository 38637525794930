// import 'babel-polyfill';
// import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js';
import 'es6-shim';
import moment from 'moment';
import 'moment/locale/zh-cn';
// // import 'core-js/es6/set'
// import 'es6-promise';



import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import './App.css';
import * as serviceWorker from './serviceWorker';

moment.locale('zh-cn');

// import { BrowserRouter as Router} from 'react-router-dom';

ReactDOM.render(<App />, document.getElementById('root'));

const rootEl = document.getElementById('root');

if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default;
      ReactDOM.render(
        <NextApp />,
        rootEl
      );
    });
  }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
