import React from 'react'
                 
// 自助服务
const Faq = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-385.000000, -289.000000)">
                    <g id="guide2" transform="translate(360.000000, 164.000000)">
                        <g id="自助服务" transform="translate(0.000000, 100.000000)">
                            <g id="icon-faq" transform="translate(20.000000, 20.000000)">
                                <rect id="Rectangle-11-Copy" x="0" y="0" width="60" height="60"></rect>
                                <path d="M5,22.5 L55,22.5 L55,55.5 C55,56.6045695 54.1045695,57.5 53,57.5 L7,57.5 C5.8954305,57.5 5,56.6045695 5,55.5 L5,22.5 Z" id="Rectangle-11" fill="#F57A7A"></path>
                                <polygon id="Rectangle-11-Copy-2" fill="#FFB5B5" points="17.5 5 41.25 5 55 22.5 5 22.5"></polygon>
                                <path d="M12,20 L22.5,20 L22.5,25 L12,25 C10.8954305,25 10,24.1045695 10,23 L10,22 C10,20.8954305 10.8954305,20 12,20 Z" id="Rectangle-9" fill="#FFFFFF"></path>
                                <path d="M39.5,20 L50,20 L50,25 L39.5,25 C38.3954305,25 37.5,24.1045695 37.5,23 L37.5,22 C37.5,20.8954305 38.3954305,20 39.5,20 Z" id="Rectangle-9-Copy" fill="#FFFFFF" transform="translate(43.750000, 22.500000) scale(-1, 1) translate(-43.750000, -22.500000) "></path>
                                <path d="M22.5,22.5 C22.5,26.6421356 25.8578644,30 30,30 C34.1421356,30 37.5,26.6421356 37.5,22.5" id="Oval-3" stroke="#FFFFFF" fill="#FFFFFF"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default Faq