import React from 'react'

const VipGuanHuai = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-687.000000, -676.000000)">
                    <g id="实物关怀福利" transform="translate(602.000000, 615.000000)">
                        <g id="icon-实物关怀" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14-Copy" x="0" y="0" width="80" height="80"></rect>
                            <g id="购物" transform="translate(15.000000, 12.000000)" fillRule="nonzero">
                                <path d="M46.2007171,53.3803287 L20.5453773,53.2023352 C19.6877721,53.1942446 18.9919792,52.4903611 19.0000698,51.6327559 L19.2266071,19.5453773 C19.2346977,18.6877721 19.9385812,17.9919792 20.7961864,18.0000698 L48.0130148,18.1942446 C48.87062,18.2023352 49.5664129,18.9062187 49.5583223,19.7638239 L49.3479663,50.2897139 C49.3317851,52.0049242 47.9240181,53.3884194 46.2007171,53.3803287 Z" id="Shape" fill="#FFF5CF"></path>
                                <path d="M44.9676375,54.1909385 L44.9352751,54.1909385 L4.69255663,53.907767 C2.11165049,53.8915858 0.0242718447,51.7799353 0.0485436893,49.1990291 L0.36407767,4.70064725 C0.372168285,3.44660194 0.865695793,2.2815534 1.75566343,1.39967638 C2.64563107,0.525889968 3.8592233,0.0242718447 5.07281553,0.0485436893 L45.315534,0.33171521 C46.5614887,0.339805825 47.7346278,0.833333333 48.6165049,1.72330097 C49.4983819,2.61326861 49.9757282,3.79449838 49.9676375,5.04045307 L49.6521036,49.538835 C49.6440129,50.7928803 49.1504854,51.9579288 48.2605178,52.8398058 C47.3705502,53.7135922 46.2055016,54.1909385 44.9676375,54.1909385 Z M5.03236246,3.17961165 C4.17475728,3.17961165 3.4789644,3.87540453 3.47087379,4.72491909 L3.15533981,49.223301 C3.15533981,49.6359223 3.30906149,50.0323625 3.60841424,50.3317152 C3.90776699,50.631068 4.2961165,50.7928803 4.70873786,50.7928803 L44.9514563,51.0760518 C45.7686084,51.0760518 46.5210356,50.3883495 46.5210356,49.5307443 L46.8365696,5.03236246 C46.8365696,4.6197411 46.6828479,4.22330097 46.3834951,3.92394822 C46.092233,3.62459547 45.7038835,3.46278317 45.2831715,3.46278317 L5.04045307,3.17961165 L5.03236246,3.17961165 Z" id="Shape" fill="#B18952"></path>
                                <path d="M28.2443366,26.9822006 L28.1877023,26.9822006 L21.8122977,26.933657 C19.7249191,26.9174757 17.7750809,26.092233 16.3106796,24.6116505 C14.8462783,23.131068 14.0533981,21.1650485 14.0614887,19.0776699 L14.1262136,10.1132686 C14.1343042,9.25566343 14.8300971,8.56796117 15.6877023,8.56796117 L15.6957929,8.56796117 C16.5614887,8.57605178 17.2491909,9.27993528 17.2411003,10.1375405 L17.1763754,19.1019417 C17.1682848,20.3478964 17.6456311,21.5291262 18.5194175,22.4190939 C19.4012945,23.3090615 20.566343,23.802589 21.8203883,23.8106796 L28.1957929,23.8592233 L28.2281553,23.8592233 C30.7928803,23.8592233 32.8883495,21.7799353 32.9045307,19.2152104 L32.9692557,10.2508091 C32.9773463,9.39320388 33.6245955,8.65695793 34.538835,8.70550162 C35.4045307,8.71359223 36.092233,9.41747573 36.0841424,10.2750809 L36.0194175,19.2394822 C36.0113269,23.5194175 32.5161812,26.9822006 28.2443366,26.9822006 Z" id="Shape" fill="#B18952"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipGuanHuai