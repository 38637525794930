import React from 'react'

// 无法登录服务器
const AccountFuwu = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -556.000000)">
                    <g id="Group-Copy-4" transform="translate(642.000000, 556.000000)">
                        <g id="icon5">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="13" y="13" width="24" height="24"></rect>
                            <path d="M34,19.75 L34,18.25 C34,16.6 32.65,15.25 31,15.25 L19,15.25 C17.35,15.25 16,16.6 16,18.25 L16,19.75 C16,20.65 16.4125,21.4375 17.0125,22 C16.4125,22.5625 16,23.35 16,24.25 L16,25.75 C16,26.65 16.4125,27.4375 17.0125,28 C16.4125,28.5625 16,29.35 16,30.25 L16,31.75 C16,33.4 17.35,34.75 19,34.75 L31,34.75 C32.65,34.75 34,33.4 34,31.75 L34,30.25 C34,29.35 33.5875,28.5625 32.9875,28 C33.625,27.4375 34,26.65 34,25.75 L34,24.25 C34,23.35 33.5875,22.5625 32.9875,22 C33.5875,21.4375 34,20.65 34,19.75 Z M32.5,31.75 C32.5,32.575 31.825,33.25 31,33.25 L19,33.25 C18.175,33.25 17.5,32.575 17.5,31.75 L17.5,30.25 C17.5,29.425 18.175,28.75 19,28.75 L31,28.75 C31.825,28.75 32.5,29.425 32.5,30.25 L32.5,31.75 Z M32.5,25.75 C32.5,26.575 31.825,27.25 31,27.25 L19,27.25 C18.175,27.25 17.5,26.575 17.5,25.75 L17.5,24.25 C17.5,23.425 18.175,22.75 19,22.75 L31,22.75 C31.825,22.75 32.5,23.425 32.5,24.25 L32.5,25.75 Z M32.5,19.75 C32.5,20.575 31.825,21.25 31,21.25 L19,21.25 C18.175,21.25 17.5,20.575 17.5,19.75 L17.5,18.25 C17.5,17.425 18.175,16.75 19,16.75 L31,16.75 C31.825,16.75 32.5,17.425 32.5,18.25 L32.5,19.75 Z M28.75,19 C28.75,19.4142136 29.0857864,19.75 29.5,19.75 C29.9142136,19.75 30.25,19.4142136 30.25,19 C30.25,18.5857864 29.9142136,18.25 29.5,18.25 C29.0857864,18.25 28.75,18.5857864 28.75,19 Z M28.75,25 C28.75,25.4142136 29.0857864,25.75 29.5,25.75 C29.9142136,25.75 30.25,25.4142136 30.25,25 C30.25,24.5857864 29.9142136,24.25 29.5,24.25 C29.0857864,24.25 28.75,24.5857864 28.75,25 Z M28.75,31 C28.75,31.4142136 29.0857864,31.75 29.5,31.75 C29.9142136,31.75 30.25,31.4142136 30.25,31 C30.25,30.5857864 29.9142136,30.25 29.5,30.25 C29.0857864,30.25 28.75,30.5857864 28.75,31 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountFuwu