import React from 'react'

const VipQuDao = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 51 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-1152.000000, -448.000000)">
                    <g id="专属沟通渠道" transform="translate(1066.000000, 383.000000)">
                        <g id="icon-专属沟通渠道" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14" x="0" y="0" width="80" height="80"></rect>
                            <g id="消息" transform="translate(16.000000, 16.000000)" fillRule="nonzero">
                                <path d="M21,30.8428571 C21,37.9357713 27.6357796,43.6857143 35.8214286,43.6857143 C44.0070775,43.6857143 50.6428571,37.9357713 50.6428571,30.8428571 C50.6428571,23.749943 44.0070775,18 35.8214286,18 C27.6357796,18 21,23.749943 21,30.8428571 Z" id="Shape" fill="#FFF5CF"></path>
                                <path d="M25,43.6 L4.9,43.6 C4.42857143,43.6 3.99285714,43.3428571 3.77142857,42.9214286 C3.55,42.5071429 3.57142857,42 3.83571429,41.6071429 L6.97857143,36.8857143 C2.47142857,32.8142857 0,27.5 0,21.8071429 C0,9.78571429 11.2142857,0.0142857143 25,0.0142857143 C38.7857143,0.0142857143 50,9.79285714 50,21.8071429 C50,33.8214286 38.7857143,43.6 25,43.6 Z M7.29285714,41.0357143 L25,41.0357143 C37.3714286,41.0357143 47.4357143,32.4071429 47.4357143,21.8071429 C47.4357143,11.2071429 37.3714286,2.57857143 25,2.57857143 C12.6285714,2.57857143 2.56428571,11.2071429 2.56428571,21.8071429 C2.56428571,27.0857143 5.02857143,32.0142857 9.50714286,35.6857143 C9.92857143,36.0285714 10.0857143,36.6 9.9,37.1071429 C9.83571429,37.2928571 9.72857143,37.4571429 9.59285714,37.5857143 L7.29285714,41.0357143 Z" id="Shape" fill="#B18952"></path>
                                <path d="M9.93571429,21.8071429 C9.93571429,23.0458387 10.9398756,24.05 12.1785714,24.05 C13.4172672,24.05 14.4214286,23.0458387 14.4214286,21.8071429 C14.4214286,20.5684471 13.4172672,19.5642857 12.1785714,19.5642857 C10.9398756,19.5642857 9.93571429,20.5684471 9.93571429,21.8071429 Z" id="Shape" fill="#B18952"></path>
                                <path d="M22.7571429,21.8071429 C22.7571428,22.6084386 23.184629,23.3488663 23.8785714,23.7495142 C24.5725138,24.1501621 25.4274862,24.1501621 26.1214286,23.7495142 C26.815371,23.3488663 27.2428572,22.6084386 27.2428571,21.8071429 C27.2428571,20.5684471 26.2386958,19.5642857 25,19.5642857 C23.7613042,19.5642857 22.7571429,20.5684471 22.7571429,21.8071429 Z" id="Shape" fill="#B18952"></path>
                                <path d="M35.5785714,21.8071429 C35.5785714,22.6084386 36.0060575,23.3488664 36.7,23.7495142 C37.3939424,24.1501621 38.2489147,24.1501621 38.9428572,23.7495142 C39.6367996,23.3488664 40.0642858,22.6084386 40.0642857,21.8071429 C40.0642858,21.0058471 39.6367996,20.2654194 38.9428572,19.8647715 C38.2489147,19.4641236 37.3939424,19.4641236 36.7,19.8647715 C36.0060575,20.2654194 35.5785714,21.0058471 35.5785714,21.8071429 Z" id="Shape" fill="#B18952"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipQuDao