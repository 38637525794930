import React from 'react'

// 高危账号问题
const AccountGaowei = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-1100.000000, -447.000000)">
                    <g id="Group-Copy-3" transform="translate(1100.000000, 447.000000)">
                        <g id="icon4">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <g id="Group-4" transform="translate(14.000000, 14.000000)">
                                <polygon id="Triangle-2" stroke="#F57A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" points="11.5 0 23 21.0833333 0 21.0833333"></polygon>
                                <path d="M10.16,7 L12.32,7 L11.92,15.144 L10.56,15.144 L10.16,7 Z M11.232,16.008 C11.584,16.008 11.888,16.12 12.128,16.344 C12.352,16.568 12.48,16.856 12.48,17.208 C12.48,17.56 12.352,17.864 12.128,18.088 C11.872,18.312 11.584,18.424 11.232,18.424 C10.88,18.424 10.592,18.296 10.368,18.072 C10.112,17.848 10,17.56 10,17.208 C10,16.856 10.112,16.568 10.368,16.344 C10.592,16.12 10.88,16.008 11.232,16.008 Z" id="！" fill="#F57A7A"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountGaowei