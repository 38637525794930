import React from 'react'

// 自助封停解封
const AccountJiefeng = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-1100.000000, -665.000000)">
                    <g id="Group-Copy-7" transform="translate(1100.000000, 665.000000)">
                        <g id="icon8">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <g id="Group-7" transform="translate(15.000000, 15.000000)">
                                <circle id="Oval-Copy-2" stroke="#F57A7A" strokeWidth="1.5" cx="10.5" cy="10.5" r="10.5"></circle>
                                <path d="M9.15834285,16.3333333 C8.96605603,16.3333333 8.77429315,16.262276 8.62811325,16.1196387 L4.21966281,11.82955 C3.92677906,11.5442756 3.92677906,11.0824027 4.21966281,10.7971282 C4.51254654,10.5118538 4.98723826,10.5118538 5.28012201,10.7971282 L9.15834285,14.5710061 L16.719878,7.21395584 C17.0127617,6.92868139 17.4874535,6.92868139 17.7803372,7.21395584 C18.0732209,7.49870779 18.0732209,7.9605807 17.7803372,8.24585515 L9.68857245,16.1196387 C9.5418686,16.262276 9.35010573,16.3333333 9.15834285,16.3333333 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountJiefeng