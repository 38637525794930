
// 记住 reducer 的职责是接收当前 state 和一个 action 然后返回新的 state。

const initialState = {
    questionActivesIndex: {
        login: 0,
        game: 0,
        recharge: 0,
        platform: 0,
        account: 0,
    },
    activeKey: '1',
    questionBreads: [],
    complaintfkey: '',
}

function questionReducer(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_QUESTION_INDEX':
            return {
                ...state,
                questionActivesIndex: {
                    ...state.questionActivesIndex,
                    [action.quizType]: action.quizTypeIndex
                }
            }
        case 'CHANGE_QUESTION_TYPE':
            return {
                ...state,
                activeKey: action.activeKey,
            }
        case 'ADD_QUESTION_BREADS':
            return {
                ...state,
                questionBreads: action.breads,
            }
        case 'ADD_COMPLAINTFKEY':
            return {
                ...state,
                complaintfkey: action.fkey,
            }
        default:
        break;
    }
    return state
}



export default questionReducer