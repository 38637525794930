import React from 'react'

const VipYiDuiYi = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-920.000000, -677.000000)">
                    <g id="VIP玩家专属活动-Copy" transform="translate(834.000000, 615.000000)">
                        <g id="icon-一对一" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14" x="0" y="0" width="80" height="80"></rect>
                            <g id="个人中心" transform="translate(16.000000, 13.000000)" fillRule="nonzero">
                                <path d="M40.0577223,52.574103 L23.9188768,52.574103 C20.2137285,52.574103 17.2184087,49.5709828 17.2184087,45.8736349 C17.2184087,42.1684867 20.2215289,39.1731669 23.9188768,39.1731669 L40.0577223,39.1731669 C43.7628705,39.1731669 46.7581903,42.1762871 46.7581903,45.8736349 C46.7581903,49.5709828 43.7550702,52.574103 40.0577223,52.574103 Z" id="Shape" fill="#FFF5CF"></path>
                                <path d="M24.9843994,26.7082683 C32.3400936,26.7082683 38.3229329,20.725429 38.3229329,13.3697348 C38.3229329,6.01404056 32.3400936,0.023400936 24.9843994,0.023400936 C17.6287051,0.023400936 11.6458658,6.00624025 11.6458658,13.3619345 C11.6458658,20.7176287 17.6287051,26.7082683 24.9843994,26.7082683 Z M24.9843994,2.87831513 C30.7644306,2.87831513 35.4680187,7.58190328 35.4680187,13.3619345 C35.4680187,19.1419657 30.7644306,23.8455538 24.9843994,23.8455538 C19.2043682,23.8455538 14.50078,19.1419657 14.50078,13.3619345 C14.50078,7.58190328 19.2043682,2.87831513 24.9843994,2.87831513 Z M37.7301092,29.5631825 L12.2464899,29.5631825 C5.50702028,29.5631825 0.023400936,35.0468019 0.023400936,41.7862715 C0.023400936,48.525741 5.50702028,54.0093604 12.2464899,54.0093604 L37.7301092,54.0093604 C44.4695788,54.0093604 49.9531981,48.525741 49.9531981,41.7862715 C49.9531981,35.0468019 44.4617785,29.5631825 37.7301092,29.5631825 Z M32.7613105,32.425897 L24.9843994,40.2028081 L17.2074883,32.425897 L32.7613105,32.425897 Z M12.2464899,51.1466459 C7.08268331,51.1466459 2.88611544,46.950078 2.88611544,41.7862715 C2.88611544,36.6224649 7.08268331,32.425897 12.2464899,32.425897 L13.1669267,32.425897 L23.9781591,43.2371295 C24.2589704,43.5179407 24.625585,43.6583463 24.9921997,43.6583463 C25.3588144,43.6583463 25.725429,43.5179407 26.0062402,43.2371295 L36.8174727,32.425897 L37.7379095,32.425897 C42.9017161,32.425897 47.0982839,36.6224649 47.0982839,41.7862715 C47.0982839,46.950078 42.8861154,51.1466459 37.7301092,51.1466459 L12.2464899,51.1466459 Z" id="Shape" fill="#B18952"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipYiDuiYi