import React from 'react'

// 找回被盗物品
const AccountQian = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -338.000000)">
                    <g id="Group" transform="translate(642.000000, 338.000000)">
                        <g id="icon1">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="13" y="13" width="24" height="24"></rect>
                            <g id="Group-2" transform="translate(13.000000, 13.000000)" fill="#F57A7A" fillRule="nonzero">
                                <path d="M15.7874481,11.4965403 L7.72683673,11.4965403 C7.29147168,11.4965403 6.93853857,11.1436072 6.93853857,10.7082421 C6.93853857,10.2728771 7.29147168,9.91994395 7.72683673,9.91994395 L15.7874481,9.91994395 C16.2228132,9.91994395 16.5757463,10.2728771 16.5757463,10.7082421 C16.5757463,11.1436072 16.2228132,11.4965403 15.7874481,11.4965403 Z M15.7874481,15.0917054 L7.72683673,15.0917054 C7.29147168,15.0917054 6.93853857,14.7387723 6.93853857,14.3034072 C6.93853857,13.8680422 7.29147168,13.5151091 7.72683673,13.5151091 L15.7874481,13.5151091 C16.2228132,13.5151091 16.5757463,13.8680422 16.5757463,14.3034072 C16.5757463,14.7387723 16.2228132,15.0917054 15.7874481,15.0917054 Z" id="Shape"></path>
                                <path d="M11.9599978,19.1582727 C11.7509281,19.1582727 11.5504214,19.0752201 11.4025868,18.9273856 C11.2547523,18.779551 11.1716996,18.5790443 11.1716996,18.3699746 L11.1716996,10.7082421 C11.1716996,10.272877 11.5246328,9.91994394 11.9599978,9.91994394 C12.3953628,9.91994394 12.748296,10.272877 12.748296,10.7082421 L12.748296,18.3699746 C12.748296,18.8053396 12.3953629,19.1582727 11.9599978,19.1582727 Z" id="Shape"></path>
                                <path d="M11.6683275,11.0324954 C11.4592216,11.0325901 11.2586455,10.9495997 11.1107379,10.8017868 L7.22758125,6.91652798 C7.02265822,6.71860639 6.9404736,6.42551457 7.01261508,6.1499025 C7.08475657,5.87429043 7.29999728,5.65904972 7.57560935,5.58690823 C7.85122142,5.51476674 8.14431324,5.59695137 8.34223484,5.8018744 L12.2274937,9.68713322 C12.452924,9.91284324 12.5200985,10.2521694 12.397666,10.5467439 C12.2752335,10.8413184 11.9873315,11.0330659 11.6683275,11.0324954 Z" id="Shape"></path>
                                <path d="M12.2500915,11.0887273 C11.9311437,11.0888719 11.6435373,10.8968075 11.5214617,10.6021462 C11.3993861,10.3074849 11.4668995,9.96829752 11.692502,9.74283963 L15.5777608,5.85758079 C15.7756824,5.65265796 16.0687742,5.5704735 16.3443861,5.64261501 C16.619998,5.71475653 16.8352387,5.92999715 16.9073802,6.20560909 C16.9795217,6.48122104 16.8973372,6.77431276 16.6924144,6.9722344 L12.8071556,10.8574932 C12.6594628,11.0053701 12.4590908,11.0885433 12.2500915,11.0887273 Z" id="Shape"></path>
                                <path d="M1.09935141,10.5984059 C0.848027148,10.5986468 0.60977112,10.4864546 0.449859812,10.2925672 C0.289948504,10.0986799 0.225149044,9.84342694 0.273214945,9.5967417 C1.35423448,4.03608654 6.25534685,0 11.9310935,0 C17.5686676,0.00033206354 22.4297592,3.96257946 23.5668998,9.48427784 C23.6600679,9.93923431 23.3667805,10.3835771 22.911824,10.4767452 C22.4568676,10.5699133 22.0125248,10.2766259 21.9193567,9.82166944 C20.9424643,5.08247193 16.7699272,1.68204964 11.9310935,1.68170273 C7.06046203,1.68170273 2.85200095,5.1454848 1.92338573,9.91836734 C1.84652299,10.3128481 1.50125013,10.5977863 1.09935141,10.5984059 Z" id="Shape"></path>
                                <path d="M1.12037269,10.6514846 C0.722530546,10.6496599 0.380415651,10.36926 0.300511398,9.97952037 C0.220607145,9.58978071 0.424784814,9.1973802 0.789813,9.03915213 L3.5583161,7.84619428 C3.83439598,7.7269993 4.15310954,7.76428065 4.39422705,7.94397452 C4.63534456,8.12366839 4.76217138,8.41842794 4.72686406,8.71705971 C4.69155674,9.01569148 4.49948854,9.27274798 4.22311423,9.39125864 L1.45250897,10.5831655 C1.34758513,10.6282573 1.2345755,10.6515029 1.12037269,10.6514846 Z M12.0677319,24 C6.4301578,23.9996679 1.56906623,20.0374205 0.431925633,14.5157222 C0.371656428,14.2214169 0.472975361,13.9173144 0.697716569,13.7179672 C0.922457777,13.5186199 1.23647773,13.4543134 1.52148814,13.5492714 C1.80649855,13.6442293 2.01919957,13.8840253 2.07946877,14.1783306 C3.05636115,18.9175281 7.22889819,22.3179504 12.0677319,22.3182973 C16.9383634,22.3182973 21.1468245,18.8545152 22.0749142,14.0816327 C22.1299922,13.7845185 22.3402607,13.5398057 22.6256968,13.4406259 C22.9111329,13.3414461 23.2278376,13.4030528 23.4552808,13.6019999 C23.6827241,13.8009471 23.785926,14.1066374 23.7256105,14.4027328 C22.644591,19.9639135 17.7413764,24 12.0677319,24 Z" id="Shape"></path>
                                <path d="M20.1088986,16.2221249 C19.7097105,16.2224386 19.3653223,15.9420401 19.2847098,15.5510761 C19.2040974,15.1601121 19.4094825,14.7663565 19.7762367,14.6087414 L22.546842,13.4168345 C22.8236562,13.2930126 23.1456979,13.3278329 23.3896479,13.5079616 C23.6335979,13.6880902 23.7616599,13.9856191 23.7247951,14.2866157 C23.6879303,14.5876123 23.4918504,14.8454417 23.2116401,14.9613734 L20.4410349,16.1532802 C20.3361489,16.1985524 20.2231378,16.2219772 20.1088986,16.2221249 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountQian