
// 记住 reducer 的职责是接收当前 state 和一个 action 然后返回新的 state。

const initialState = {
    isLogin: '',
    isShowNews: 0,
    qucLoginStatus: false,
    isShowOnlineService: false,
    isShowCover: false,
}

function showReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_LOGIN_STATUS':
            return {
                ...state,
                isLogin: action.isLogin,
            }
        case 'CHANGE_SHOW_NEWS':
            return {
                ...state,
                isShowNews: action.isShowNews
            }
        case 'CHANGE_QUC_LOGIN':
            return {
                ...state,
                qucLoginStatus: action.qucLoginStatus
            }
        case 'SET_IM':
            return {
                ...state,
                isShowOnlineService: action.isShowOnlineService
            }
        case 'SET_COVER':
            return {
                ...state,
                isShowCover: action.isShowCover
            }
        case 'SET_SUBMIT_COVER':
            return {
                ...state,
                submitCover: action.submitCover
            }
        default:
        break;
    }
    return state
}



export default showReducer