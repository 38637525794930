import React from 'react'

const VipLiBao = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-1384.000000, -448.000000)">
                    <g id="游戏礼包特权" transform="translate(1298.000000, 383.000000)">
                        <g id="icon-游戏礼包" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14" x="0" y="0" width="80" height="80"></rect>
                            <g id="活动" transform="translate(16.000000, 16.000000)" fillRule="nonzero">
                                <path d="M21.720121,26.6944024 L42.3252648,26.6944024 C43.8608169,26.6944024 45.1013616,27.934947 45.1013616,29.4704992 L45.1013616,44.6974281 L18.9440242,44.6974281 L18.9440242,29.4629349 C18.9440242,27.934947 20.1921331,26.6944024 21.720121,26.6944024 Z" id="Shape" fill="#FFF5CF"></path>
                                <path d="M45.8245083,6.63388805 L28.3736762,6.63388805 L32.5945537,2.41301059 C33.1391831,1.87594554 33.1391831,0.998487141 32.5945537,0.453857791 C32.0574887,-0.0907715582 31.1800303,-0.0907715582 30.6354009,0.453857791 L25.0226929,6.06656581 L19.4099849,0.453857791 C18.8729198,-0.0907715582 17.9954614,-0.0907715582 17.4508321,0.453857791 C16.9062027,0.990922844 16.9062027,1.86838124 17.4508321,2.41301059 L21.6717095,6.63388805 L4.22087746,6.63388805 C1.92889561,6.63388805 0.0605143722,8.50226929 0.0605143722,10.7942511 L0.0605143722,16.7624811 C0.0605143722,18.7594554 1.47503782,20.4311649 3.35854766,20.8320726 L3.35854766,39.8108926 C3.35854766,43.6384266 6.47503782,46.7473525 10.2950076,46.7473525 L39.7503782,46.7473525 C43.5779123,46.7473525 46.6868381,43.6384266 46.6792738,39.8184569 L46.6792738,20.8320726 C48.5627837,20.4387292 49.9848714,18.7594554 49.9848714,16.7624811 L49.9848714,10.7942511 C49.9848714,8.50226929 48.1164902,6.63388805 45.8245083,6.63388805 Z M2.8366112,16.7624811 L2.8366112,10.7942511 C2.8366112,10.0302572 3.45688351,9.40998487 4.22087746,9.40998487 L23.6384266,9.40998487 L23.6384266,18.1467474 L4.22087746,18.1467474 C3.45688351,18.1467474 2.8366112,17.526475 2.8366112,16.7624811 Z M6.13464448,39.8184569 L6.13464448,20.9228442 L23.6384266,20.9228442 L23.6384266,43.97882 L10.2950076,43.97882 C8.00302572,43.97882 6.13464448,42.1104387 6.13464448,39.8184569 Z M43.9107413,39.8184569 C43.9107413,42.1104387 42.0423601,43.97882 39.7503782,43.97882 L26.4069592,43.97882 L26.4069592,20.9228442 L43.9107413,20.9228442 L43.9107413,39.8184569 Z M47.2087746,16.7624811 C47.2087746,17.526475 46.5885023,18.1467474 45.8245083,18.1467474 L26.4069592,18.1467474 L26.4069592,9.40998487 L45.8245083,9.40998487 C46.5885023,9.40998487 47.2087746,10.0302572 47.2087746,10.7942511 L47.2087746,16.7624811 Z" id="Shape" fill="#B18952"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipLiBao