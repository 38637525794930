import React from 'react'

const VipAnQuan = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 40 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-1156.000000, -678.000000)">
                    <g id="账号安全保护" transform="translate(1066.000000, 615.000000)">
                        <g id="icon-账号安全" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14" x="0" y="0" width="80" height="80"></rect>
                            <g id="安全-(1)" transform="translate(20.000000, 14.000000)">
                                <path d="M18,25 L40,25 L40,42.6666667 C36.3333333,48.5555556 32.6666667,51.5 29,51.5 C25.3333333,51.5 21.6666667,48.5555556 18,42.6666667 L18,25 Z" id="Rectangle-15" fill="#FFF5CF"></path>
                                <path d="M19.9734101,0.0285467626 C14.283741,0.100546763 16.5755971,4.39315108 0.00270503597,4.46647482 L0.00270503597,29.9846331 C0.00270503597,41.0141007 17.4077122,51.0648058 19.9734101,51.0648058 C22.5391079,51.0648058 39.9441151,41.0141007 39.9441151,29.9846331 L39.9441151,4.46647482 C23.6485755,4.49709353 25.455482,-0.0408057554 19.9734101,0.0285467626 Z M37.7251223,30.0569784 C37.7251223,38.9168921 21.2157698,48.8344173 19.9733525,48.8458705 C18.7364029,48.8573237 2.29093525,38.8475396 2.22158273,30.0569784 L2.22158273,7.31251799 C16.9530935,7.24719424 14.9158561,3.4211223 19.9733525,3.35700719 C24.8463309,3.29519424 23.2401151,7.33985612 37.7251223,7.31251799 L37.7251223,30.0569784 Z M12.3578705,22.7729496 C11.9246043,22.3396835 11.2221007,22.3396835 10.7888345,22.7729496 C10.3555683,23.2062158 10.3555683,23.9087194 10.7888345,24.3419856 L17.8496115,31.4027626 C18.2828777,31.8360288 18.9853813,31.8360288 19.4186475,31.4027626 L31.3768633,19.4445468 C31.8101295,19.0112806 31.8101295,18.308777 31.3768633,17.8755108 C30.9435971,17.4422446 30.2410935,17.4422446 29.8078273,17.8755108 L18.6341295,29.0492086 L12.3578705,22.7729496 Z" id="Shape" fill="#B18952" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipAnQuan