
import React from 'react'

const QuestionCircle = (props) => {
    const {width, height} = props;
    return (
      <section className="icon-line-height">
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>question-circle</title>
        
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="首页-热门问题" transform="translate(-626.000000, -312.000000)">
                <g id="q1" transform="translate(626.000000, 310.000000)">
                    <g id="question-circle" transform="translate(0.000000, 2.000000)">
                        <rect id="Rectangle-14" fill="#FF999F" x="0" y="0" width="20" height="20"></rect>
                        <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="0" y="0" width="20" height="20"></rect>
                        <g id="Group" transform="translate(3.000000, 3.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M7,0 C3.134375,0 0,3.134375 0,7 C0,10.865625 3.134375,14 7,14 C10.865625,14 14,10.865625 14,7 C14,3.134375 10.865625,0 7,0 Z M7,12.8125 C3.790625,12.8125 1.1875,10.209375 1.1875,7 C1.1875,3.790625 3.790625,1.1875 7,1.1875 C10.209375,1.1875 12.8125,3.790625 12.8125,7 C12.8125,10.209375 10.209375,12.8125 7,12.8125 Z" id="Shape"></path>
                            <path d="M8.74375,3.9484375 C8.275,3.5375 7.65625,3.3125 7,3.3125 C6.34375,3.3125 5.725,3.5390625 5.25625,3.9484375 C4.76875,4.375 4.5,4.9484375 4.5,5.5625 L4.5,5.68125 C4.5,5.75 4.55625,5.80625 4.625,5.80625 L5.375,5.80625 C5.44375,5.80625 5.5,5.75 5.5,5.68125 L5.5,5.5625 C5.5,4.8734375 6.1734375,4.3125 7,4.3125 C7.8265625,4.3125 8.5,4.8734375 8.5,5.5625 C8.5,6.0484375 8.15625,6.49375 7.6234375,6.6984375 C7.2921875,6.825 7.0109375,7.046875 6.809375,7.3375 C6.6046875,7.634375 6.4984375,7.990625 6.4984375,8.3515625 L6.4984375,8.6875 C6.4984375,8.75625 6.5546875,8.8125 6.6234375,8.8125 L7.3734375,8.8125 C7.4421875,8.8125 7.4984375,8.75625 7.4984375,8.6875 L7.4984375,8.3328125 C7.4984375,8.025 7.6921875,7.74375 7.98125,7.6328125 C8.903125,7.278125 9.4984375,6.465625 9.4984375,5.5625 C9.5,4.9484375 9.23125,4.375 8.74375,3.9484375 Z" id="Shape"></path>
                            <path d="M6.375,10.4375 C6.375,10.782678 6.65482203,11.0625 7,11.0625 C7.34517797,11.0625 7.625,10.782678 7.625,10.4375 C7.625,10.092322 7.34517797,9.8125 7,9.8125 C6.65482203,9.8125 6.375,10.092322 6.375,10.4375 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        </svg>
      </section>
    )
}
                          
export default QuestionCircle