import React from 'react'
                         
const HotLine = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 58 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-382.000000, -492.000000)">
                    <g id="guide2" transform="translate(360.000000, 164.000000)">
                        <g id="客服热线" transform="translate(0.000000, 300.000000)">
                            <g id="icon-hotline" transform="translate(20.000000, 20.000000)">
                                <rect id="Rectangle-11-Copy" x="0" y="0" width="60" height="60"></rect>
                                <path d="M55,33.75 C55,20.6332372 44.3667628,10 31.25,10 C18.1332372,10 7.5,20.6332372 7.5,33.75" id="Oval-4" stroke="#F57A7A" strokeWidth="3"></path>
                                <ellipse id="Oval-5" fill="#F57A7A" cx="6.25" cy="35" rx="3.75" ry="5"></ellipse>
                                <ellipse id="Oval-5-Copy" fill="#F57A7A" cx="56.25" cy="35" rx="3.75" ry="5"></ellipse>
                                <circle id="Oval-6" fill="#F57A7A" cx="31.25" cy="33.75" r="18.75"></circle>
                                <path d="M17.5,32.5 C17.5,40.7842712 23.6560847,47.5 31.25,47.5 C38.8439153,47.5 45,40.7842712 45,32.5 C40.4166667,32.5 35.8333333,32.5 31.25,32.5" id="Oval-6-Copy" fill="#FFFFFF"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default HotLine