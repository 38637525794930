import React from 'react'

const OnlineCopy = (props) => {
    const {width, height} = props;
    return (
      <section className="add-pointer">
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>close copy 2</title>
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="首页-已登录" transform="translate(-1691.000000, -177.000000)">
                    <g id="在线咨询" transform="translate(1570.000000, 164.000000)">
                        <g id="close-copy-2" transform="translate(116.000000, 8.000000)">
                            <rect id="Rectangle-27" x="0" y="0" width="24" height="24"></rect>
                            <polygon id="Shape" fill="#FFFFFF" points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default OnlineCopy