import React from 'react'
                         
const Vip = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-387.000000, -391.000000)">
                    <g id="guide2" transform="translate(360.000000, 164.000000)">
                        <g id="贵宾服务" transform="translate(0.000000, 200.000000)">
                            <g id="icon-vip" transform="translate(20.000000, 20.000000)">
                                <rect id="Rectangle-11-Copy" x="0" y="0" width="60" height="60"></rect>
                                <circle id="Oval-7" fill="#FFB5B5" cx="31.25" cy="18.75" r="11.25"></circle>
                                <path d="M55,52.5 C55,40.0735931 44.3667628,30 31.25,30 C18.1332372,30 7.5,40.0735931 7.5,52.5" id="Oval-8" fill="#FFB5B5"></path>
                                <polygon id="Triangle" fill="#FFFFFF" transform="translate(31.250000, 38.750000) scale(1, -1) translate(-31.250000, -38.750000) " points="31.25 30 37.5 47.5 25 47.5"></polygon>
                                <circle id="Oval-9" fill="#FFFFFF" cx="46.5" cy="45.5" r="10.5"></circle>
                                <circle id="Oval-9-Copy" fill="#F57A7A" cx="46.5" cy="45.5" r="8.5"></circle>
                                <polygon id="V" fill="#FFFFFF" points="40.7282913 40 43.1296889 40 46.8357827 51.0048297 46.8925086 51.0048297 50.5986025 40 53 40 48.1972049 53.5007704 45.5310864 53.5007704"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default Vip