import React from 'react'
                         
const Myquiz = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 51 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="登录注册" transform="translate(-385.000000, -189.000000)">
                    <g id="guide2" transform="translate(360.000000, 164.000000)">
                        <g id="我的问题">
                            <g id="icon-my" transform="translate(20.000000, 20.000000)">
                                <rect id="Rectangle-11-Copy" x="0" y="0" width="60" height="60"></rect>
                                <rect id="Combined-Shape" fill="#FFB5B5" x="5" y="5" width="50" height="50" rx="2"></rect>
                                <path d="M20,5 L56,5 L56,55 L20,55 C18.8954305,55 18,54.1045695 18,53 L18,7 C18,5.8954305 18.8954305,5 20,5 Z" id="Rectangle-10" fill="#F57A7A" transform="translate(37.000000, 30.000000) scale(-1, 1) translate(-37.000000, -30.000000) "></path>
                                <rect id="Rectangle-6" fill="#FFFFFF" x="5" y="18" width="13" height="5"></rect>
                                <rect id="Rectangle-6-Copy" fill="#FFFFFF" x="5" y="28" width="13" height="5"></rect>
                                <rect id="Rectangle-6-Copy-2" fill="#FFFFFF" x="5" y="38" width="13" height="5"></rect>
                                <path d="M37.4172185,15 C39.9602649,15 42.0264901,15.6771978 43.615894,17.1112637 C45.205298,18.5054945 46,20.4175824 46,22.8076923 C46,24.7596154 45.4834437,26.3928571 44.4900662,27.6675824 C44.1324503,28.1057692 43.0198675,29.1414835 41.1523179,30.7747253 C40.397351,31.4120879 39.8410596,32.1291209 39.4834437,32.8461538 C39.0463576,33.6428571 38.8476821,34.5192308 38.8476821,35.4752747 L38.8476821,36.1524725 L36.9801325,36.1524725 L34.5960265,36.1524725 L34.5960265,35.4752747 C34.5960265,34.0013736 34.8344371,32.7266484 35.3907285,31.6909341 C35.9072848,30.5755495 37.4172185,28.9423077 39.8807947,26.7513736 L40.5562914,25.9945055 C41.2715232,25.0782967 41.6688742,24.1222527 41.6688742,23.0865385 C41.6688742,21.6923077 41.2715232,20.6167582 40.5165563,19.8200549 C39.7218543,19.0233516 38.5695364,18.625 37.1390728,18.625 C35.3509934,18.625 34.0397351,19.1826923 33.2450331,20.3379121 C32.5298013,21.293956 32.1721854,22.6483516 32.1721854,24.4010989 L28,24.4010989 C28,21.4931319 28.8344371,19.2225275 30.5033113,17.5494505 C32.1721854,15.8365385 34.4768212,15 37.4172185,15 Z M36.7019868,38.3035714 C37.5364238,38.3035714 38.2516556,38.5425824 38.807947,39.1002747 C39.3642384,39.6181319 39.6423841,40.2953297 39.6423841,41.1318681 C39.6423841,41.9684066 39.3245033,42.6456044 38.7682119,43.2032967 C38.2119205,43.7211538 37.4966887,44 36.7019868,44 C35.9072848,44 35.192053,43.7211538 34.6357616,43.1634615 C34.0794702,42.6057692 33.8013245,41.9285714 33.8013245,41.1318681 C33.8013245,40.2953297 34.0794702,39.6181319 34.6357616,39.1002747 C35.192053,38.5425824 35.9072848,38.3035714 36.7019868,38.3035714 Z" id="？" fill="#FFFFFF"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default Myquiz