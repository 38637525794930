import React from 'react'

// 投诉
const AccountTousu = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -774.000000)">
                    <g id="Group-Copy-8" transform="translate(642.000000, 774.000000)">
                        <g id="icon9">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <rect id="Rectangle-path" fill="#000000" fillRule="nonzero" opacity="0" x="13" y="13" width="24" height="24"></rect>
                            <path d="M33.1375,22.2953125 C32.7648437,22.2953125 32.4625,22.5976563 32.4625,22.9703125 L32.4625,31.8625 C32.4625,32.1273438 32.246875,32.3429688 31.9820312,32.3429688 L18.08125,32.3429688 C17.8164062,32.3429688 17.6007812,32.1273438 17.6007812,31.8625 L17.6007812,17.9617188 C17.6007812,17.696875 17.8164062,17.48125 18.08125,17.48125 L27.175,17.48125 C27.5476562,17.48125 27.85,17.1789063 27.85,16.80625 C27.85,16.4335938 27.5476562,16.13125 27.175,16.13125 L18.08125,16.13125 C17.0710937,16.13125 16.2507812,16.9515625 16.2507812,17.9617188 L16.2507812,31.8625 C16.2507812,32.8726563 17.0710937,33.6929688 18.08125,33.6929688 L31.9820312,33.6929688 C32.9921875,33.6929688 33.8125,32.8726563 33.8125,31.8625 L33.8125,22.9703125 C33.8125,22.5976563 33.5101562,22.2953125 33.1375,22.2953125 Z M20.7320312,29.209375 C20.8632812,29.340625 21.0367187,29.40625 21.2101562,29.40625 C21.3835937,29.40625 21.5546875,29.340625 21.6882812,29.209375 L33.615625,17.2820313 C33.8804687,17.0171875 33.8804687,16.590625 33.615625,16.3257813 C33.3507812,16.0609375 32.9242187,16.0609375 32.659375,16.3257813 L20.7320312,28.2554688 C20.4695312,28.5179688 20.4695312,28.946875 20.7320312,29.209375 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountTousu