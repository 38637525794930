import React from 'react'

// 投诉
const AccountWanhui = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-1100.000000, -774.000000)">
                    <g id="Group-Copy-9" transform="translate(1100.000000, 774.000000)">
                        <g id="icon10">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <g id="Group-8" transform="translate(16.000000, 15.000000)">
                                <rect id="Rectangle-8" stroke="#F57A7A" strokeWidth="1.5" x="0.75" y="0.75" width="18.5" height="18.5" rx="2"></rect>
                                <path d="M10.75,14.5 C10.75,14.9142136 10.4142136,15.25 10,15.25 C9.58578644,15.25 9.25,14.9142136 9.25,14.5 L9.25,5.5 C9.25,5.08578644 9.58578644,4.75 10,4.75 C10.4142136,4.75 10.75,5.08578644 10.75,5.5 L10.75,14.5 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                                <path d="M10.531,15.031 C10.2377368,15.3242632 9.76226321,15.3242632 9.46900001,15.031 C9.17573681,14.7377368 9.17573681,14.2622632 9.469,13.969 L13.7125,9.727 C14.0067842,9.44277084 14.4745636,9.44683572 14.7638639,9.73613607 C15.0531643,10.0254364 15.0572292,10.4932158 14.773,10.7875 L10.531,15.031 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                                <path d="M9.412,15.031 C9.72364434,15.3234221 10.2088557,15.3234221 10.5205,15.031 C10.6673703,14.8934542 10.7507121,14.7012206 10.7507121,14.5 C10.7507121,14.2987794 10.6673703,14.1065458 10.5205,13.969 L6.088,9.727 C5.77635566,9.43457793 5.29114434,9.43457793 4.9795,9.727 C4.83311498,9.864506 4.75008753,10.0564106 4.75008753,10.25725 C4.75008753,10.4580894 4.83311498,10.649994 4.9795,10.7875 L9.412,15.031 Z" id="Shape" fill="#F57A7A" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountWanhui