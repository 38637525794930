import React from 'react'

const IconAnnouncement = (props) => {
    const { width, height } = props
    return (
      <section>
         <style jsx>{`
             
         `}
         </style>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 14 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="首页-已登录" transform="translate(-615.000000, -178.000000)" fillRule="nonzero">
                    <g id="公告" transform="translate(602.000000, 164.000000)">
                        <g id="icon-公告" transform="translate(12.000000, 12.000000)">
                            <rect id="Rectangle-path" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
                            <path d="M14.4046875,7.321875 L11.7125,7.321875 C11.565625,7.321875 11.425,7.38125 11.321875,7.4859375 C11.21875,7.590625 11.1609375,7.73125 11.1609375,7.878125 L11.1609375,8.0515625 C11.1609375,8.3578125 11.409375,8.60625 11.7140625,8.60625 L14.4046875,8.60625 C14.709375,8.60625 14.95625,8.3578125 14.95625,8.0515625 L14.95625,7.878125 C14.95625,7.73125 14.8984375,7.590625 14.7953125,7.4859375 C14.6921875,7.38125 14.5515625,7.321875 14.4046875,7.321875 Z M8.1625,2.5609375 C7.325,3.2265625 5.5734375,4.159375 4.3140625,5.0578125 L4.3125,5.0578125 C4.2984375,5.0671875 4.284375,5.1171875 4.26875,5.125 C4.1203125,5.209375 4.053125,5.4390625 3.24375,5.4390625 L1.665625,5.4390625 C1.259375,5.4390625 1.009375,5.6296875 1.009375,6.0578125 L1.009375,9.859375 C1.009375,10.2875 1.2390625,10.4984375 1.665625,10.4984375 L3.25,10.4984375 C4.0546875,10.5 4.1203125,10.7296875 4.26875,10.8125 C4.2828125,10.8203125 4.296875,10.8703125 4.3109375,10.8796875 L4.3125,10.8796875 C5.534375,11.778125 7.296875,12.7828125 8.1625,13.421875 C8.4234375,13.6140625 9.2890625,13.9515625 9.2890625,12.7640625 L9.2890625,3.1703125 C9.2890625,1.98125 8.415625,2.3609375 8.1625,2.5609375 Z M10.646875,5.1828125 C10.790625,5.434375 11.0734375,5.4984375 11.2796875,5.3234375 L13.490625,3.459375 C13.6984375,3.2859375 13.7484375,2.940625 13.60625,2.6875 L13.525,2.5453125 C13.3828125,2.29375 13.0984375,2.23125 12.8921875,2.4046875 L10.68125,4.26875 C10.475,4.44375 10.4234375,4.7875 10.565625,5.040625 L10.646875,5.1828125 Z M11.278125,10.6109375 C11.071875,10.4375 10.7890625,10.5015625 10.646875,10.7515625 L10.565625,10.89375 C10.4234375,11.1453125 10.475,11.490625 10.68125,11.665625 L12.8921875,13.53125 C13.0984375,13.7046875 13.38125,13.6421875 13.5234375,13.390625 L13.6046875,13.2484375 C13.746875,12.996875 13.6953125,12.6515625 13.4890625,12.4765625 L11.278125,10.6109375 Z" id="Shape" fill="#C1222B"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default IconAnnouncement