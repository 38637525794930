import React from 'react'
                         
const FormDelete = (props) => {
    const {width, height} = props;
   return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 13 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="公告列表" transform="translate(-1603.000000, -307.000000)">
                    <g id="table-item-1" transform="translate(235.000000, 296.000000)">
                        <g id="icon-删除" transform="translate(1364.000000, 7.000000)">
                            <rect id="Rectangle-5" x="0" y="0" width="22" height="22"></rect>
                            <g id="Group" transform="translate(4.000000, 4.000000)" fill="#AAAAAA" fillRule="nonzero">
                                <path d="M12.4621271,3.7920882 L0.546303502,3.7920882 C0.249546044,3.7920882 0.0084306096,3.55097276 0.0084306096,3.2542153 L0.0084306096,3.24747082 C0.0084306096,2.95071336 0.249546044,2.70959792 0.546303502,2.70959792 L12.4621271,2.70959792 C12.7588846,2.70959792 13,2.95071336 13,3.24747082 L13,3.2542153 C13,3.55097276 12.7588846,3.7920882 12.4621271,3.7920882 Z M10.1808042,14.590013 L2.85460441,14.590013 C2.1919585,14.590013 1.65239948,14.050454 1.65239948,13.387808 L1.65239948,5.41582361 C1.65239948,5.11738003 1.89351492,4.87626459 2.1919585,4.87626459 C2.49040208,4.87626459 2.73151751,5.11738003 2.73151751,5.41582361 L2.73151751,13.387808 C2.73151751,13.4552529 2.78715953,13.5108949 2.85460441,13.5108949 L10.1808042,13.5108949 C10.248249,13.5108949 10.3038911,13.4552529 10.3038911,13.387808 L10.3038911,5.41582361 C10.3038911,5.11738003 10.5450065,4.87626459 10.8434501,4.87626459 C11.1418936,4.87626459 11.3830091,5.11738003 11.3830091,5.41582361 L11.3830091,13.387808 C11.3830091,14.050454 10.8434501,14.590013 10.1808042,14.590013 Z" id="Shape"></path>
                                <path d="M4.89143969,11.9040208 C4.59299611,11.9040208 4.35188067,11.6629053 4.35188067,11.3644617 L4.35188067,6.51348898 C4.35188067,6.2150454 4.59299611,5.97392996 4.89143969,5.97392996 C5.18988327,5.97392996 5.4309987,6.2150454 5.4309987,6.51348898 L5.4309987,11.3644617 C5.4309987,11.6629053 5.18988327,11.9040208 4.89143969,11.9040208 Z M8.12879377,11.8854734 C7.83035019,11.8854734 7.58923476,11.644358 7.58923476,11.3459144 L7.58923476,6.49494163 C7.58923476,6.19649805 7.83035019,5.95538262 8.12879377,5.95538262 C8.42723735,5.95538262 8.66835279,6.19649805 8.66835279,6.49494163 L8.66835279,11.3459144 C8.66835279,11.644358 8.42723735,11.8854734 8.12879377,11.8854734 Z M9.73398184,1.66588846 C9.43553826,1.66588846 9.19442283,1.42477302 9.19442283,1.12632944 L9.19442283,1.11115435 L9.19442283,1.092607 L3.82581064,1.092607 L3.82581064,1.12632944 C3.82581064,1.42477302 3.5846952,1.66588846 3.28625162,1.66588846 C2.98780804,1.66588846 2.74669261,1.42477302 2.74669261,1.12632944 L2.74669261,1.11115435 C2.74669261,0.846433204 2.82762646,0.591828794 2.97600519,0.394552529 C3.15642023,0.153437095 3.42114137,0.0151750973 3.70272374,0.0151750973 L9.31919585,0.0151750973 C9.60077821,0.0151750973 9.86549935,0.153437095 10.0459144,0.394552529 C10.1942931,0.591828794 10.275227,0.846433204 10.275227,1.11115435 L10.275227,1.12632944 C10.2735409,1.42477302 10.0324254,1.66588846 9.73398184,1.66588846 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
   )
}
                          
export default FormDelete