import React from 'react'

const VipZhuanShu = (props) => {
    const {width, height} = props;
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 54 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="贵宾服务" transform="translate(-917.000000, -446.000000)">
                    <g id="VIP玩家专属活动" transform="translate(834.000000, 383.000000)">
                        <g id="icon-VIP专属" transform="translate(70.000000, 49.000000)">
                            <rect id="Rectangle-14" x="0" y="0" width="80" height="80"></rect>
                            <g id="icon" transform="translate(15.000000, 16.000000)">
                                <polygon id="Star-Copy" fill="#FFF5CF" points="32.2029977 43.55526 22.1889944 48.8199331 24.1014988 37.6691765 16 29.7721669 27.195996 28.1452934 32.2029977 18 37.2099993 28.1452934 48.4059954 29.7721669 40.3044965 37.6691765 42.217001 48.8199331"></polygon>
                                <polygon id="Star" stroke="#B18952" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" points="25.2029977 39.75 9.6266885 47.9389504 12.6014988 30.5944752 -3.55493412e-13 18.3110496 17.4148431 15.7805248 25.2029977 0 32.9911523 15.7805248 50.4059954 18.3110496 37.8044965 30.5944752 40.7793069 47.9389504"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default VipZhuanShu