import React from 'react'

// 举报诈骗、不良网址
const AccountZhapian = (props) => {
    const { width, height } = props
    return (
      <section>
        <svg width={width+'px'} height={height+'px'} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="快速表单" transform="translate(-642.000000, -665.000000)">
                    <g id="Group-Copy-6" transform="translate(642.000000, 665.000000)">
                        <g id="icon7">
                            <circle id="Oval-5" fill="#FFEBEB" cx="25" cy="25" r="25"></circle>
                            <g id="Group-5" transform="translate(15.000000, 15.000000)">
                                <circle id="Oval" stroke="#F57A7A" strokeWidth="1.5" cx="10.5" cy="10.5" r="10.5"></circle>
                                <path d="M10.154,6 L11.904,6 L11.582,13.168 L10.476,13.168 L10.154,6 Z M11.022,13.994 C11.316,13.994 11.568,14.078 11.764,14.274 C11.96,14.456 12.058,14.694 12.058,14.988 C12.058,15.282 11.96,15.52 11.764,15.716 C11.554,15.898 11.302,15.996 11.022,15.996 C10.742,15.996 10.504,15.898 10.308,15.702 C10.098,15.506 10,15.268 10,14.988 C10,14.694 10.098,14.456 10.308,14.274 C10.504,14.078 10.742,13.994 11.022,13.994 Z" id="！" fill="#F57A7A"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AccountZhapian